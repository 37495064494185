import { render, staticRenderFns } from "./UserIntegral.vue?vue&type=template&id=615cfd86&scoped=true"
import script from "./UserIntegral.vue?vue&type=script&lang=js"
export * from "./UserIntegral.vue?vue&type=script&lang=js"
import style0 from "./UserIntegral.vue?vue&type=style&index=0&id=615cfd86&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "615cfd86",
  null
  
)

export default component.exports
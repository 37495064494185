<template>
  <div class="content" style="padding: 20px 100px">
    <!-- 其他用户中心内容 -->
    <div class="points">
      <h2>
        当前积分: <span>{{ points }}</span>
      </h2>
    </div>
  </div>
</template>

<script>
import { get_token } from "../common/common.js";
// import UserSetting from "./UserSetting.vue";
export default {
  data() {
    return {
      backgroundImage:
        "http://www.soutudashi.com/get_image/" +
        encodeURIComponent("bg/20771591-6dae-4970-9bc6-46db70c9b51c.webp"), // 默认背景图
      points: 100, // 示例积分
      is_show: false,
    };
  },
  methods: {
    setBackgroundImage(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.backgroundImage = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    mouse_leave() {
      this.is_show = false;
    },
    mouse_enter() {
      this.is_show = true;
    },
    async get_current_integral() {
      try {
        const token = get_token("token");
        var response = await this.$http({
          method: "post",
          url: "http://www.soutudashi.com/get_integral", // 拼接URL和参数
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 设置跨域参数
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${token}`,
          },
          responseType: "json",
        });

        // var response = await this.$http.get(
        //   "http://www.soutudashi.com/get_integral",
        //   {
        //     headers: { "Content-Type": "application/json" },
        //   }
        // );

        // console.log("获取赞助数据：1 ", response.data.data);
        return await response.data.data.num;
        // }
      } catch (e) {
        console.log(e);
      } finally {
        // this.comment_content = "";
      }
    },
  },

  // components: {
  //   UserSetting,
  // },

  async mounted() {
    this.points = await this.get_current_integral();
  },
};
</script>

<style scoped>
.user-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4; /* 背景色 */
}
.header {
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.content {
  width: 100%;
  box-sizing: border-box;
}
.points h2 {
  color: #333;
  font-size: 24px;
}
.points span {
  color: #e74c3c; /* 积分颜色 */
  font-weight: bold;
}
</style>
